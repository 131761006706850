import { useBlogQuery, useRecipeTagsQuery } from "@api";
import { Container, P } from "@util/standard";
import styled from "styled-components";

import * as React from "react";
import { navigate } from "gatsby";
import { assets } from "@util/constants";
import {
  Maybe,
  SanityBlogCategory,
  SanityBlogCategoryConnection,
  SanityRecipeTagConnection,
} from "@graphql-types";

interface Props {
  isRecipes?: boolean;
  isBlogs?: boolean;
  selectedCategory?: Maybe<string> | undefined;
  selectedBlogCategory?: Maybe<SanityBlogCategory> | undefined;
  isAll?: boolean;
  allSanityBlogCategory?: SanityBlogCategoryConnection;
  allSanityRecipeTag?: SanityRecipeTagConnection;
}

const StyledTitleContainer = styled(Container)<{
  isOpen?: boolean;
  isCurrent?: boolean;
}>`
  border-bottom: 1px solid;
  transition: 0.3s all ease;
  ${({ isCurrent }) =>
    isCurrent &&
    `
    pointer-events: none;
  `}
  &:hover {
    opacity: 0.5;
  }
`;

const CategoryMenu = (props: Props) => {
  const {
    isRecipes,
    selectedCategory,
    isAll,
    isBlogs,
    selectedBlogCategory,
    allSanityBlogCategory,
    allSanityRecipeTag,
  } = props;

  // const { allSanityRecipeTag } = useRecipeTagsQuery();
  if (allSanityRecipeTag == null) {
    return null;
  }

  // const { allSanityBlogCategory } = useBlogQuery();

  return (
    <>
      {isRecipes && (
        <Container width="230px" display="flex" flexDirection="column">
          {allSanityRecipeTag.nodes.map((category, i) => {
            if (category?.main == null) return;

            const isCurrent = selectedCategory === category.main.slug?.current;
            return (
              <StyledTitleContainer
                width="100%"
                tabletWidth="92.5%"
                margin="0px auto"
                key={i}
                isCurrent={isCurrent}
                cursor="pointer"
                onClick={() =>
                  navigate(`/recipes/${category.main?.slug?.current}`)
                }
              >
                <Container
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <P fontSize={15} bold={isCurrent}>
                    {category.main.title}
                  </P>
                  <img
                    src={assets.arrowRight}
                    alt="Arrow Right"
                    width="8"
                    height="16"
                  />
                </Container>
              </StyledTitleContainer>
            );
          })}
          <StyledTitleContainer
            width="230px"
            tabletWidth="92.5%"
            margin="0px auto"
            cursor="pointer"
            onClick={() => navigate(`/recipes-all`)}
          >
            <Container
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <P fontSize={15} bold={isAll ? true : false}>
                All Recipes
              </P>
              <img
                src={assets.arrowRight}
                alt="Arrow Right"
                width="8"
                height="16"
              />
            </Container>
          </StyledTitleContainer>
        </Container>
      )}

      {/* Blogs */}
      {isBlogs && (
        <Container width="230px" display="flex" flexDirection="column">
          {allSanityBlogCategory &&
            allSanityBlogCategory.nodes.map(category => {
              if (category?.main == null) return;

              const isCurrent =
                selectedBlogCategory?.main?.slug?.current ===
                category.main.slug?.current;

              return (
                <StyledTitleContainer
                  width="100%"
                  tabletWidth="92.5%"
                  margin="0px auto"
                  key={category.main.title}
                  isCurrent={isCurrent}
                  cursor="pointer"
                  onClick={() =>
                    navigate(`/blog/${category.main?.slug?.current}`)
                  }
                >
                  <Container
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                  >
                    <P fontSize={15} bold={isCurrent}>
                      {category.main.title}
                    </P>
                    <img
                      src={assets.arrowRight}
                      alt="Arrow Right"
                      width="8"
                      height="16"
                    />
                  </Container>
                </StyledTitleContainer>
              );
            })}
          <StyledTitleContainer
            width="230px"
            tabletWidth="92.5%"
            margin="0px auto"
            cursor="pointer"
            onClick={() => navigate(`/blogs`)}
          >
            <Container
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <P fontSize={15} bold={isAll ? true : false}>
                All Food Blogs
              </P>
              <img
                src={assets.arrowRight}
                alt="Arrow Right"
                width="8"
                height="16"
              />
            </Container>
          </StyledTitleContainer>
        </Container>
      )}
    </>
  );
};

export default CategoryMenu;
